import { PUBLIC_APP_HOST } from "$env/static/public";
import * as Sentry from '@sentry/sveltekit';

if (!PUBLIC_APP_HOST.includes('localhost')) {
  Sentry.init({
    dsn: 'https://7578587ec45a4d13b7aae330e8ff2117@o986255.ingest.sentry.io/6269776',
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [Sentry.replayIntegration()],
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();